import React from "react"

import styled from "@emotion/styled"

import {
  BodyBold,
  colours,
  breakpoints,
  typographyStyles,
  IntercomIcon,
  HowToRegIcon,
  Card,
} from "design-kit"

import keysIcon from "./icons/keys-wings.png"

const CardTitle = styled(BodyBold)`
  padding-bottom: 32px;
`

const Li = styled.li`
  padding-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  & :last-of-type {
    padding-bottom: 0;
  }
  & :before {
    position: absolute;
    left: 10px;
    z-index: 1;
    top: 0;
    content: "";
    height: 100%;
    width: 0;
    border-left: 1px solid ${colours.greyScale.grey50};
  }
  & :last-of-type:before {
    height: 30%;
  }
  & :first-of-type:before {
    top: 10px;
    height: 100%;
  }
`

const IconBg = styled.div`
  margin-right: 20px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  margin-left: -10px;
  z-index: 3;
  background-color: ${colours.greyScale.grey25};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WingsIcon = styled.img`
  max-height: 50px;
  max-width: 65px;
  min-height: 50px;
  min-width: 65px;
  margin-left: -21px;
  margin-right: 10px;
  z-index: 3;
  margin-top: -5px;
`

const BulletPoints = styled.span`
  ${typographyStyles.body};
  ${breakpoints.desktop`
    ${typographyStyles.bodySmall};
  `}
`

const CardStyled = styled(Card)`
  align-self: center;
`

export const TimelineContent: React.FC = () => (
  <ul>
    <Li>
      <IconBg>
        <HowToRegIcon />
      </IconBg>
      <div>
        <BodyBold>Add your details</BodyBold>
        <BulletPoints>Tell us some more about you</BulletPoints>
      </div>
    </Li>
    <Li>
      <IconBg>
        <IntercomIcon />
      </IconBg>

      <div>
        <BodyBold>Get your own adviser</BodyBold>
        <BulletPoints>
          Chat to a qualified human, who’ll match you to a mortgage
        </BulletPoints>
      </div>
    </Li>
    <Li>
      <WingsIcon src={keysIcon} alt="" />
      <div>
        <BodyBold>We sort your mortgage, for free</BodyBold>
        <BulletPoints>
          We’ll apply for you, and keep you updated until it comes through
        </BulletPoints>
      </div>
    </Li>
  </ul>
)

export const TimelineCard: React.FC = () => (
  <CardStyled element="div">
    <CardTitle>Here's how it works:</CardTitle>
    <TimelineContent />
  </CardStyled>
)
