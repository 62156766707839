import styled from "@emotion/styled"

import {
  Body,
  BodySmallBold,
  colours,
  typographyStyles,
  breakpoints,
  vertical,
  horizontal,
} from "design-kit"

export const CardTitle = styled.h1`
  ${typographyStyles.headlineBold};
  color: ${colours.offBlack};
  padding-bottom: ${vertical.s};
`

export const CtaBox = styled.div`
  margin-top: ${vertical.xxs};
  margin-bottom: ${vertical.m};
`

export const EmailInput = styled.input`
  border: 1px solid ${colours.offBlack};
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
  width: 100%;
  padding-left: ${horizontal.s};
  margin-bottom: ${vertical.s};
  ${typographyStyles.body};
  ::-webkit-input-placeholder {
    /* Edge */
    color: ${colours.greyScale.grey100};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colours.greyScale.grey100};
  }
  ::placeholder {
    color: ${colours.greyScale.grey100};
  }
  &:focus-within {
    border: 3px solid ${colours.callout.dark};
  }
`

export const BodyText = styled.div`
  margin-bottom: 16px;
  ${typographyStyles.body};
  color: ${colours.offBlack};
  ${breakpoints.desktop`
    ${typographyStyles.bodySmall};
  `}
`

export const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid ${colours.greyScale.grey50};
  padding: 4px 0;
  width: 100%;
`

export const BodySmallFullWidth = styled.div`
  margin-bottom: ${vertical.xs};
  display: flex;
  flex-direction: row;
`

export const HorizontalLineText = styled(BodySmallBold)`
  color: ${colours.greyScale.grey100};
  padding: 0 ${horizontal.s};
  ${breakpoints.tablet`
  padding: 0 ${horizontal.m};
  `}
`

export const Error = styled(BodyText)`
  color: ${colours.destructive.main};
  font-weight: bold;
  display: block;
  margin: 0 0 20px;
  width: 100%;
`

export const PasswordInput = styled.input`
  border: 1px solid ${colours.greyScale.grey100};
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
  width: 100%;
  padding-left: 16px;
  margin-bottom: 24px;
  ${typographyStyles.body};
  ::-webkit-input-placeholder {
    /* Edge */
    color: ${colours.greyScale.grey100};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colours.greyScale.grey100};
  }
  ::placeholder {
    color: ${colours.greyScale.grey100};
  }
  &:focus-within {
    border: 3px solid ${colours.callout.dark};
  }
`

export const UserEmailBold = styled(Body)`
  margin-bottom: ${vertical.xs};
  font-weight: bold;
  color: ${colours.offBlack};
`

export const KeysImg = styled.img`
  width: 116px;
  padding-bottom: 20px;
  margin-top: -8px;
`

export const SocialButtonsContainer = styled.div`
  & > :first-of-type {
    margin-bottom: ${vertical.xs};
  }

  margin-bottom: ${vertical.m};
`

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`
