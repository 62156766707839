import createAuth0Client, { Auth0Error } from "auth0-js"
import { SentryLoggerSimple } from "@heyhabito/sentry"
import { GlobalHabitoConfig, GlobalAuth0Config } from "../types/config"

declare global {
  interface Window {
    HABITO_CONFIG: GlobalHabitoConfig
  }
}

export function getCookie(key: string): string | undefined {
  return (("; " + document.cookie).split("; " + key + "=").pop() || "")
    .split(";")
    .shift()
}

export function removeAuthCookie(key: string): void {
  const cookie = document.cookie
  if (!!key && cookie.indexOf(key) !== -1) {
    const secure = window.location.protocol === "https:"
    const time0 = new Date(0).toUTCString()
    document.cookie = [
      `${key}=`,
      "path=/",
      `expires=${time0}`,
      "SameSite=Strict",
      secure ? "Secure" : "",
      `domain=${window.HABITO_CONFIG.auth0Config.cookieBaseDomain}`,
    ].join("; ")
  }
}

const setMarketingOptInCookie = (marketingOptIn: boolean): void => {
  document.cookie =
    "authFlows_marketingOptIn=" +
    (marketingOptIn ? "true" : "false") +
    ";path=/;domain=" +
    window.HABITO_CONFIG.auth0Config.cookieBaseDomain +
    ";max-age=3600"
}

export const buildAuth0Config = (
  baseAuth0Config: GlobalAuth0Config,
  destination: string | null
): GlobalAuth0Config => {
  return {
    ...baseAuth0Config,
    redirectUri:
      window.location.origin +
      baseAuth0Config.redirectUri +
      (destination !== null ? `?destination=${destination}` : ""),
  }
}

export const sendMagicLink = (
  email: string,
  marketingOptIn: boolean,
  log: SentryLoggerSimple,
  destination: string | null
): Promise<void> =>
  new Promise((resolve, reject) => {
    setMarketingOptInCookie(marketingOptIn)

    const a0Client = new createAuth0Client.WebAuth(
      buildAuth0Config(window.HABITO_CONFIG.auth0Config, destination)
    )

    a0Client.passwordlessStart(
      {
        connection: "email",
        send: "link",
        email: email,
      },
      (err: Auth0Error | null) => {
        if (err) {
          log("Error calling passwordlessStart", auth0ErrorToError(err))
          reject()
        } else {
          resolve()
        }
      }
    )
  })

export type SignUpError = "UserExists" | "UnknownError"

// This can't be just any freely selected URL; it must be one of a hardcoded
// set of options, thanks to limitations of what we can do with Auth0. Each
// option corresponds to a different "Application" in Auth0. We customise the
// verification email link destination based on which Application the sign-up
// request came from.
export type PasswordDestination =
  | "mortgage-in-principle"
  | "track-your-remortgage"
  | "msm"
  | "muve"
  | "buying-power"

export const signUpWithPassword = (
  email: string,
  password: string,
  marketingOptIn: boolean,
  log: SentryLoggerSimple,
  passwordDestination: PasswordDestination | null
): Promise<void> =>
  new Promise((resolve, reject) => {
    setMarketingOptInCookie(marketingOptIn)

    const auth0Config = buildAuth0Config(
      (() => {
        switch (passwordDestination) {
          case null:
            return window.HABITO_CONFIG.auth0Config
          case "mortgage-in-principle":
            return window.HABITO_CONFIG.mipAuth0Config
          case "track-your-remortgage":
            return window.HABITO_CONFIG.trackYourRemortgageAuth0Config
          case "msm":
            return window.HABITO_CONFIG.msmAuth0Config
          case "muve":
            return window.HABITO_CONFIG.muveAuth0Config
          case "buying-power":
            return window.HABITO_CONFIG.buyingPowerAuth0Config
        }
      })(),
      // the various config above provide their own `?destination=`
      null
    )

    const a0Client = new createAuth0Client.WebAuth(auth0Config)

    a0Client.redirect.signupAndLogin(
      {
        connection: "HabitoLegacyDB",
        email,
        password,
      },
      (err: Auth0Error | null) => {
        if (err) {
          if (err.code === "user_exists") {
            reject("UserExists")
          } else {
            log("Error calling redirect.signupAndLogin", auth0ErrorToError(err))
            reject("UnknownError")
          }
        } else {
          resolve()
        }
      }
    )
  })

export const authWithGoogle = (
  marketingOptIn: boolean,
  destination: string | null
): void => {
  setMarketingOptInCookie(marketingOptIn)

  const a0Client = new createAuth0Client.WebAuth(
    buildAuth0Config(window.HABITO_CONFIG.auth0Config, destination)
  )

  a0Client.authorize({
    connection: "google-oauth2",
  })
}

export const authWithFacebook = (
  marketingOptIn: boolean,
  destination: string | null
): void => {
  setMarketingOptInCookie(marketingOptIn)

  const a0Client = new createAuth0Client.WebAuth(
    buildAuth0Config(window.HABITO_CONFIG.auth0Config, destination)
  )

  a0Client.authorize({
    connection: "facebook",
  })
}

export const resetPassword = (
  email: string,
  log: SentryLoggerSimple
): Promise<void> =>
  new Promise((resolve, reject) => {
    const a0Client = new createAuth0Client.WebAuth(
      buildAuth0Config(window.HABITO_CONFIG.auth0Config, null)
    )

    a0Client.changePassword(
      {
        connection: "HabitoLegacyDB",
        email,
      },
      (err: Auth0Error | null) => {
        if (err) {
          log("Error calling reset password", auth0ErrorToError(err))
          reject()
        } else {
          resolve()
        }
      }
    )
  })

const auth0ErrorToError = (e: Auth0Error): Error => ({
  message: "Auth0 error",
  name: "Auth0Error",
  ...e,
})
