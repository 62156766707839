import React from "react"
import styled from "@emotion/styled"

import { ChevronUpIcon, ChevronDownIcon, colours, Body } from "design-kit"

interface ItemContainerProps {
  isOpen: boolean
}

const ItemContainer = styled.div<ItemContainerProps>`
  transition-timing-function: ease-in-out;
  transition: all 0.5s ease;
  transition: 0.25s;
  overflow-y: hidden;
  max-height: ${props => (!props.isOpen ? "56px" : "456px")};
  background-color: ${colours.white};
  transform: ${props =>
    !props.isOpen ? `translateY(calc(100% - 56px))` : `translateY(0%)`};
`

const QuestionRow = styled.button`
  display: flex;
  border: none;
  width: 100%;
  flex-direciton: row;
  align-items: center;
  cursor: pointer;
  padding: 0 32px;
  height: 56px;
  border-radius: 6px 6px 0px 0px;
  background-color: ${colours.offBlack};
`

const Question = styled(Body)`
  padding-left: 24px;
  color: ${colours.white};
  font-weight: bold;
`

const ChevronContainer = styled.div`
  margin: 0;
`

const TimelineWrapper = styled.div`
  padding: 32px;
  background-color: white;
  margin: 0 auto;
`

interface FaqQuestionProps {
  isOpen: boolean
  clickHandler: () => void
  title: string
}

const FaqQuestion: React.FunctionComponent<FaqQuestionProps> = ({
  isOpen,
  clickHandler,
  title,
  children,
}) => (
  <ItemContainer isOpen={isOpen}>
    <QuestionRow onClick={clickHandler}>
      <ChevronContainer>
        {isOpen ? (
          <ChevronUpIcon color={colours.white} />
        ) : (
          <ChevronDownIcon color={colours.white} />
        )}
      </ChevronContainer>
      <Question spacing="none">{title}</Question>
    </QuestionRow>
    <TimelineWrapper>{children}</TimelineWrapper>
  </ItemContainer>
)

interface TimelineSliderProps {
  title: string
}

const TimelineSlider: React.FunctionComponent<TimelineSliderProps> = ({
  title,
  children,
}) => {
  const [isOpen, toggleIsOpen] = React.useState(false)
  return (
    <FaqQuestion
      title={title}
      isOpen={isOpen}
      clickHandler={() => toggleIsOpen(!isOpen)}
    >
      {children}
    </FaqQuestion>
  )
}

export default TimelineSlider
